import "@mod-publisher/js/richcontent/all"; // support rich content (video, images)
import "@mod-system/js/wh/integration"; // activate WebHare features
import * as dompack from "dompack";
import { openLinksInNewWindow } from "@mod-publisher/js/linkhandler";
import "./shared/forms/forms";
import * as dialog from "dompack/components/dialog";
import * as dialogapi from "dompack/api/dialog";
import * as defaultshop from "@mod-webshop/js/composer/defaultshop";
import CustomListCartWidget from "@mod-diveworld/webdesigns/diveworldshop/pages/checkoutpage/custom-cart-widget";

import {
  photopagehandler,
  customphotopagehandler,
} from "./pages/productpage/productpage.es";
// import WhBase from '@mod-system/js/compat/base';
import * as whintegration from "@mod-system/js/wh/integration";
import * as cookie from "dompack/extra/cookie";
import setupDatalayer from "@mod-webshop/js/analytics/gtm";
// import '@mod-publisher/js/analytics/gtm';
import $ from "jquery";

import "font-awesome/css/font-awesome.min.css";
import "magnific-popup/dist/magnific-popup.css";

import "./diveworldshop.scss";

import "./smartmenus/smartmenus";
import "./web/swiper/swiper.min.css";

import "./shared/custom";

// components
import "@mod-diveworld/components/footer/footer.scss";
import "./header/header.scss";
import "./embeddedobjects/embeddedobjects";
import "./mobilemenu/mobilemenu";
import "@mod-diveworld/components/slideshow/slideshow.es";
import "@mod-mijnsiteonline/components/photoalbum/photoalbum.es";
import "@mod-mso/webdesigns/mso/widgets/ctas/ctas.scss";

// pages
import "./pages/";
import "./pages/products/products";

import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

openLinksInNewWindow();
setupDatalayer();

let touchsupport =
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;
if (!touchsupport) {
  // browser doesn't support touch
  document.documentElement.className += " non-touch";
}

function onNewCartDom(dom) {
  const nodes = dompack.qSA(dom, ".webshop-listcart__productdelete");
  for (let idx = 0; idx < nodes.length; idx += 1) {
    nodes[idx].textContent = "Verwijderen";
  }
}

function shopReady(webshop) {
  refreshCartStatus(webshop);
  dompack.register(
    "webshop-cart-custom",
    (node) =>
      new CustomListCartWidget(webshop, node, {
        inlinediscounts: undefined,
        onNewCartDom: onNewCartDom,
      })
  );
}

function refreshCartStatus(webshop) {
  let num = webshop.getCart().numarticles;
  let $numInCart = $("#header-numincart");
  $numInCart.text(num).toggleClass("show", num > 0);
}

addEventListener("webshop:cartupdated", function (evt) {
  refreshCartStatus(evt.detail.webshop);
});

// addEventListener('webshop:productadded', function (evt) {
//   if (!evt.defaultPrevented) {
//     evt.preventDefault();
//     evt.detail.webshop.gotoCheckout();
//   }
// });

dialogapi.setupDialogs((options) => dialog.createDialog("wh-dialog", options));

defaultshop.setup({
  onReady: shopReady,
  onNewCartDom: onNewCartDom,
});

dompack.onDomReady(() => {
  if (shouldLoadVideos()) loadIFrames();
  else {
    let classNames = [".wh-video", ".organizations__googlemap", "yt-video"];

    setupCookiewall(classNames);
  }

  let submenus = dompack.qSA(".custommenu__dropdown__item.has-submenu");
  // console.log(submenus);
  submenus.forEach((element) => {
    // console.log("a");
    element
      .querySelector("span.custommenu__dropdown__subheader")
      .addEventListener("click", (event) => {
        console.log("a");
        let sublist = element.querySelector(
          ".custommenu__dropdown__item__list"
        );
        sublist.classList.toggle("show");
      });

    // element.addEventListener("click", (event) => {
    // 	let sublist = element.querySelector(".custommenu__dropdown__item__list--sublist");
    // 	sublist.classList.toggle("show");
    // });
  });

  let searchbutton = dompack.qS("#searchbutton");
  searchbutton.addEventListener("click", (evt) => {
    if (
      dompack.qS("html.searchexpanded") == undefined ||
      dompack.qS(".searchbar").value == ""
    )
      evt.preventDefault();
    else searchbutton.submit();

    // Waiting for the transition to finish
    setTimeout(function () {
      dompack.qS("#searchbar").focus();
    }, 500);
    dompack.qS("html").classList.toggle("searchexpanded");
  });

  dompack.qS("#searchoverlay").addEventListener("click", (evt) => {
    dompack.qS("html").classList.toggle("searchexpanded");
  });

  let enterTimeout;
  let leaveTimeout;

  $(".nav-submenu").mouseover(function () {
    leaveTimeout && (clearTimeout(leaveTimeout), (leaveTimeout = 0));

    let correction = ($(window).width() * 0.11) / 2;
    let elementOffset = $(this).offset().left;
    let elementWidth = $(this).width();

    let offset = elementOffset - correction + elementWidth / 2 - 9;
    enterTimeout = setTimeout(function () {
      $(".mainsubarrow").css({ opacity: "1", left: offset });
      $(".white").css("left", offset + 1);
    }, 350);
  });

  $(".nav-submenu").mouseleave(function () {
    enterTimeout && (clearTimeout(enterTimeout), (enterTimeout = 0));
    leaveTimeout = setTimeout(function () {
      $(".mainsubarrow").animate({ opacity: `0` }, "fast");
    }, 350);
  });

  let elBlocksGrid = dompack.qS("#blocks-grid");
  if (!elBlocksGrid) return;

  let masonrySettings = {
    itemSelector: ".grid-item",
    columnWidth: ".grid-sizer",
    percentPosition: true,
    transitionDuration: 500,
    gutter: 0, // also set in .scss
    horizontalOrder: true,
  };

  let grid = new Masonry(elBlocksGrid, masonrySettings);

  imagesLoaded(elBlocksGrid, () => {
    grid.layout();
  });
});

function shouldLoadVideos() {
  if (
    whintegration.config.obj.applycookiewall === false ||
    !whintegration.config.obj.cookiewallname
  )
    return true;

  let cookieVal = cookie.read(whintegration.config.obj.cookiewallname);
  return (
    whintegration.config.obj.applycookiewall === true &&
    cookieVal &&
    cookieVal.includes("marketing")
  );
}

window.refreshOnCookieWallChange = () => {
  if (shouldLoadVideos()) loadIFrames();
};

function loadIFrames() {
  // Load iFrames (Youtube / Google Maps)
  require("@mod-publisher/js/richcontent/all");

  for (const gmap of dompack.qSA(".organizations__googlemap")) {
    let iFrameLink = gmap.getAttribute("data-iframe");

    let iFrameHTML = `<iframe src="${iFrameLink}" width="100%" height="480"></iframe>`;

    gmap.insertAdjacentHTML("beforeend", iFrameHTML);
  }
}

function setupCookiewall(classNames) {
  for (const className of classNames) {
    for (const cookiewallObj of dompack.qSA(className)) {
      cookiewallObj.classList.add(`iframe--cookiewalled`);

      let addHTML = `<div class="cookiewallcontainer">
           <p class="cookiewalltext">
             Om deze embedded content (Video's, Google Maps) te zien, moet je marketing cookies accepteren.
           </p>
           <div class="cookiewallbutton">
             <a class="button js-open-cookie-prefs">Pas je cookievoorkeuren aan</a>
           </div>
         </div>`;

      cookiewallObj.innerHTML = addHTML;
    }
  }
}
